<template>
  <div id="Header">
    <div
      v-show="btn"
      :class="[
        activeClass ? 'header' : 'headers',
        isShadow ? 'header-shadow' : '',
      ]"
      :style="{
        background: activeClass ? bgColor[initialIndex] : '',
      }"
    >
      <div :class="[activeClass ? 'header-1' : 'header-2']" v-if="showTop">
        <div class="header-1-1" v-if="activeClass">
          <!-- <img :src="fontAssetsURL(`firstpage/图层 1495 拷贝.png`)" alt="" v-if="!this.showme" /> -->
          <img
            :src="fontAssetsURL(`firstpage/logo-01.svg`)"
            style="height:66px; width: 172px; padding-top: 5px;"
            v-if="!this.showme"
          />
        </div>
        <div class="header-1-1" v-if="!activeClass">
          <!-- <img :src="fontAssetsURL(`firstpage/图层 1495.png`)" alt="" v-if="!this.showme" /> -->
          <img
            :src="fontAssetsURL(`firstpage/logo-02.svg`)"
            style="height:66px; width: 172px; padding-top: 5px;"
            v-if="!this.showme"
          />
        </div>
        <div class="header-1-2">
          <div
            v-for="(item, index) in headerList"
            :key="item.id"
            @click="go(index)"
            :class="{ active: currentfirstitem == index }"
          >
            <span v-if="index !== 1">
              {{ item.title }}
            </span>
            <div v-else ref="download" class="download">
              下载
              <div class="downloadTag">
                <div @click="download(1)">浏览器下载</div>
                <!-- <div @click="download(2)">Scratch下载</div> -->
                <!-- <div @click="download(3)">客户端下载</div> -->
                <div @click="download(5)">教师端</div>
                <div @click="download(6)">家长端</div>
                <div @click="download(4)">后台适配版</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 身份登录 -->
        <div
          ref="login"
          class="header-1-3"
          @mouseover="mouseoverLogin"
          @mouseleave="mouseleaveLogin"
          v-show="currentfirstitem !== 6 && !isShopping"
        >
          登录
          <div class="header-1-3-item">
            <div class="student" @click="login(0)">我是学生</div>
            <div class="teacher" @click="login(1)">我是老师</div>
          </div>
        </div>
        <!-- 商城登录 -->
        <div class="header-1-4 da" v-show="currentfirstitem === 6">
          <el-dropdown @command="handleCommand">
            <div class="da" style="margin-right:5px">
              <i
                class="el-icon-user-solid"
                style="font-size: 20px;color: #000000;"
              ></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                icon="el-icon-user-solid"
                command="1"
                v-if="!IsLogin"
                @click="toLogin"
                >登录</el-dropdown-item
              >
              <el-dropdown-item
                icon="el-icon-s-finance"
                command="2"
                v-if="IsLogin"
                >全部订单</el-dropdown-item
              >
              <el-dropdown-item
                icon="el-icon-user"
                command="3"
                v-if="IsLogin"
                @click="toLogin"
                >退出</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <div
            class="da icon-with-badge"
            style="margin-left:10px;"
            @click="toCart"
          >
            <i
              class="el-icon-shopping-cart-1"
              style="font-size: 22px;color: #000000;"
            ></i>
            <span class="badge" v-if="$store.state.cartCount">{{
              $store.state.cartCount
            }}</span>
          </div>
        </div>
        <div
          v-show="currentfirstitem === 6"
          style="width: 200px;display: flex;align-items: center;cursor: pointer;"
          @click="toShopping"
        >
          全部商品
        </div>
        <el-autocomplete
          v-show="isShopping"
          placeholder="请输入商品信息"
          icon="search"
          v-model="goodName"
          style="width: 200px;margin: 0 auto;"
          :minlength="1"
          :maxlength="100"
          :fetch-suggestions="querySearchAsync"
          @select="handleSelect"
          :on-icon-click="handleIconClick"
          @keydown.enter.native="handleIconClick"
        >
        </el-autocomplete>
      </div>
    </div>
    <el-carousel
      @change="changeInitialIndex"
      class="carousel"
      v-if="$route.path === '/'"
    >
      <el-carousel-item v-for="item in 2" :key="item">
        <el-image
          class="img"
          :src="fontAssetsURL(`firstpage/banner${item == 1 ? 3 : 2}.png`)"
          fit="fill"
        ></el-image>
      </el-carousel-item>
    </el-carousel>
    <keep-alive>
      <router-view />
    </keep-alive>
    <div class="line8" v-show="btn">
      <div class="line8-ctx">
        <div class="line8-1">
          <div class="line8-1-1">
            <!-- <img :src="fontAssetsURL(`firstpage/图层 1495 拷贝.png`)" alt="" v-if="!this.showme" /> -->
            <img
              style="height:30px;width: 100px;"
              :src="fontAssetsURL(`firstpage/logo-01.svg`)"
              v-if="!this.showme"
            />
          </div>
          <div class="line8-1-2" style="margin: 0;">
            <img :src="fontAssetsURL(`firstpage/erweima.jpg`)" alt="" />
          </div>
        </div>
        <div class="line8-2">
          <div class="title">关于我们</div>
          <div class="content">
            湖南果实智能科技有限公司以“AI赋能教育，数据驱动学习、自主智能系统”为目标，依托于浙大专家团队联合开发的晓果智学3.0自适应学习系统，公司立足于全国前沿教育，致力于基于未来科技教育的现代化解决方案，多项技术和产品获得国家专利，并取得了国家教育部、信息产业部等多部门的资质认证。
          </div>
        
        </div>
        <div class="line8-3">
          <div class="title">联系方式</div>
          <div class="phone">
            <img
              :src="fontAssetsURL(`firstpage/图层 43.png`)"
              alt=""
              v-if="!this.showme"
            />
            <img
              :src="fontAssetsURL(`firstpage/图层 43@2x.png`)"
              alt=""
              v-if="this.showme"
            />
            <span>电话：</span>
            <span>15616705276</span>
          </div>
          <div class="email">
            <img
              :src="fontAssetsURL(`firstpage/图层 44.png`)"
              alt=""
              v-if="!this.showme"
            />
            <img
              :src="fontAssetsURL(`firstpage/图层 44@2x.png`)"
              alt=""
              v-if="this.showme"
            />
            <span>邮箱：</span>
            <span>guoshiedu@qq.com</span>
          </div>
          <div class="address">
            <img
              :src="fontAssetsURL(`firstpage/图层 45.png`)"
              alt=""
              v-if="!this.showme"
            />
            <img
              :src="fontAssetsURL(`firstpage/图层 45@2x.png`)"
              alt=""
              v-if="this.showme"
            />
            <span>地址：</span>
            <span>长沙市芙蓉区古汉国际广场1栋11楼</span>
          </div>
          <!-- <div class="ssl-address">
            <el-dropdown>
              <span class="el-dropdown-link">
                证书
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <a
                  href="https://xyt.xcc.cn/getpcInfo?sn=1742021509418795008&language=CN&certType=8&url=*.xiaoguoai.cn"
                  target="_blank"
                  style="position: relative; display: inline-block; height: 38px; z-index: 99999;"
                >
                  <div
                    style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
                  ></div>
                  <embed
                    src="https://program.xinchacha.com/web/1742021509418795008=*.xiaoguoai.cn.svg"
                    width="103"
                    height="38"
                    type="image/svg+xml"
                    pluginspage="//www.adobe.com/svg/viewer/install/"
                /></a>
                </el-dropdown-item>
              
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
        </div>
      </div>
    </div>
    <div class="line9" v-show="btn">
      <div class="line9-ctx">
        Copyright © 2003-2021 晓果智学.All Rights Reserved
        <a class="a_targt" target="_blank" href="http://beian.miit.gov.cn"
          >湘ICP备2023007847号</a
        >
        &nbsp;&nbsp;&nbsp;&nbsp;
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
             <span style="color: #949494;">证</span>
            </span>
            <el-dropdown-menu  slot="dropdown">
              <el-dropdown-item>
                <a
                href="https://xyt.xcc.cn/getpcInfo?sn=1742021509418795008&language=CN&certType=8&url=*.xiaoguoai.cn"
                target="_blank"
                style="position: relative; display: inline-block; height: 38px; z-index: 99999;"
              >
                <div
                  style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
                ></div>
                <embed
                  src="https://program.xinchacha.com/web/1742021509418795008=*.xiaoguoai.cn.svg"
                  width="103"
                  height="38"
                  type="image/svg+xml"
                  pluginspage="//www.adobe.com/svg/viewer/install/"
              /></a>
              </el-dropdown-item>
            
            </el-dropdown-menu>
          </el-dropdown>
      </div>
   
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      timer: null,
      bgColor: {
        0: "#1472ff",
        1: "#ee8c49",
        // 0: "#f6c250",
        // 1: "#ee8c49",
        // 2: "#1472ff",
        transparent: "transparent",
      },
      initialIndex: 0,
      goodName: "", //搜索内容
      isShopping: false, //是否为商城板块，身份登录按钮隐藏
      headerList: [], //header列表
      currentfirstitem: 0, //header当前项
      showme: 0, //大小图片显示切换 0 | 1 == 小 | 大
      activeClass: true, //样式切换
      showTop: true, //滚动到100距离隐藏顶部
      showLogin: 1, //登录框
      isShadow: false,
      btn: true,
      isShowDownload: false,
    };
  },
  watch: {
    //考虑浏览器前进后退
    $route: {
      handler(to) {
        if (to.path === "/scratchdownload") {
          this.isShowDownload = true;
        } else {
          this.isShowDownload = false;
        }
        // 如果打开的为login页面,则关闭页头页脚,添加title,meta
        if (to.path === "/shopping/login" || to.path === "/shopping/register") {
          this.btn = false;
        } else {
          this.btn = true;
        }
        // 是否为商城板块，身份登录按钮隐藏
        if (to.path.includes("shopping")) {
          this.isShopping = true;
        } else {
          this.isShopping = false;
        }
        const compArr = [
          "/",
          "/browserdownload",
          "/help",
          "/news",
          "/join",
          "/info",
          "/shopping/shoppingMail",
        ];
        let idx = compArr.findIndex((item) => {
          return item === to.path;
        });
        if (idx !== 0) {
          this.activeClass = false;
        } else {
          this.activeClass = true;
        }
        this.currentfirstitem = idx === 8 ? 3 : idx;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    IsLogin() {
      return this.$store.state.login;
    },
  },
  created() {
    this.getRoute();
    this.getData();
  },
  mounted() {
    //滚动到2500距离出现返回顶部图标
    window.addEventListener("scroll", () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const routerArr = [
        "/",
        "/browserdownload",
        "/help",
        "/news",
        "/join",
        "/info",
        "/shopping/shoppingMail",
      ];
      if (scrollTop > 700 && this.$route.path === "/") {
        this.activeClass = false;
        this.isShadow = true;
      }
      if (scrollTop < 700 && this.$route.path === "/") {
        this.activeClass = true;
        this.isShadow = false;
      }
      if (scrollTop > 377 && this.$route.path === "/news") {
        this.isShadow = true;
      }
      if (scrollTop < 377 && this.$route.path === "/news") {
        this.isShadow = false;
      }
    });
  },
  methods: {
    ...mapMutations([
      "SET_SHOPPINGLOGIN",
      "SET_TOKEN",
      "SET_SHOPPINGCARTCOUNT",
    ]),
    debounce(func, delay) {
      let that = this;
      return function() {
        const context = this;
        const args = arguments;
        clearTimeout(that.timer);
        that.timer = setTimeout(() => {
          func.apply(context, args);
        }, delay);
      };
    },
    changeHandle(e) {
      this.debounce(() => (this.initialIndex = e), 1000)();
    },
    changeInitialIndex(e) {
      this.initialIndex = "transparent";
      this.changeHandle(e);
    },
    handleIconClick() {
      this.$router.push({
        path: "/shopping/search",
        query: {
          key: this.goodName,
        },
      });
    },
    handleSelect(item) {
      this.goodName = item.value;
    },
    querySearchAsync(queryString, cb) {
      if (this.input === undefined) {
        cb([]);
        return;
      }
      this.input = this.input.trim();
      if (this.input === "") {
        cb([]);
        return;
      } else {
        this.loadAll();
        setTimeout(() => {
          cb(this.searchResults);
        }, 300);
      }
    },
    toCart() {
      // login true 在线 false 登录
      if (!this.IsLogin) {
        this.$router.push({
          path: "/shopping/login",
        });
      } else {
        this.$router.push({
          path: "/shopping/cart",
        });
      }
    },
    handleCommand(command) {
      if (command === "2") {
        this.$router.push({
          path: "/shopping/order",
        });
      } else {
        this.toLogin();
      }
    },
    toShopping() {
      this.$router.push({
        path: "/shopping/goods",
      });
    },
    toLogin() {
      // login true 在线 false 登录
      if (this.IsLogin) {
        this.SET_SHOPPINGLOGIN(false);
        this.SET_TOKEN(null);
        this.SET_SHOPPINGCARTCOUNT(null);
        this.$message({
          message: "退出成功",
          type: "success",
        });
      } else {
        this.$router.push({
          path: "/shopping/login",
        });
      }
    },
    getRoute() {
      let url = window.location.href;
      let lastName = url.split("/");
      let name = lastName[lastName.length - 1];
      // 如果打开的为share页面,则关闭页头页脚,添加title,meta
      if (name === "share" || name === "login" || name === "register") {
        this.btn = false;
      } else {
        this.btn = true;
      }
      // 是否为商城板块，身份登录按钮隐藏
      if (window.location.href.includes("/shopping")) {
        this.isShopping = true;
      } else {
        this.isShopping = false;
      }
    },
    //获取数据
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      // let a = currentWidth > 1920 ? '@2x' : ''
      this.showme = currentWidth > 1920 ? 0 : 0;
      this.headerList = [
        {
          id: 0,
          title: "首页",
        },
        {
          id: 1,
          title: "浏览器下载",
        },
        // {
        //     id:1,
        //     title:'客户端下载'
        // },
        {
          id: 2,
          title: "使用帮助",
        },
        {
          id: 3,
          title: "新闻中心",
        },
        {
          id: 4,
          title: "合作咨询",
        },
        {
          id: 5,
          title: "了解企业",
        },
        // {
        //   id: 6,
        //   title: "商城",
        // },
      ];
    },
    //路由
    go(param) {
      this.initialIndex = "transparent";
      if (param !== 1) {
        const routerArr = [
          "/",
          "",
          "/help",
          "/news",
          "/join",
          "/info",
          "/shopping/shoppingMail",
        ];
        if (routerArr[param] === this.$route.path) return;

        // 当点击新闻页的时候，提醒分页跳转到首页
        if (param === 3) {
          this.$bus.$emit("back");
        }

        if (param !== 0) {
          this.activeClass = false;
        } else {
          this.activeClass = true;
        }
        this.$router.push({
          path: routerArr[param],
        });
        this.currentfirstitem = param;
      } else {
        // console.log("okkkkkkkkkkk", param);
      }
    },
    download(value) {
      if (this.$route.path !== "/browserdownload" && value === 1) {
        this.$router.push("/browserdownload");
      } else if (this.$route.path !== "/scratchdownload" && value === 2) {
        this.$router.push("/scratchdownload");
      } else if (this.$route.path !== "/clientdownload" && value === 3) {
        this.$router.push("/clientdownload");
      } else if (
        (this.$route.path !== "/backendAdaptationVersion" ||
          this.$route.query.id != value) &&
        [4, 5, 6].includes(value)
      ) {
        this.$router.push({
          path: "/backendAdaptationVersion",
          query: {
            id: value,
          },
        });
      }
    },
    mouseoverLogin() {
      this.showLogin = 1;
      this.$refs.login.style.height = "177px";
    },
    mouseleaveLogin() {
      this.showLogin = 0;
      this.$refs.login.style.height = "77px";
    },
    showDownload() {
      this.$refs.download[0].style.height = "100px";
    },
    hideDownload() {
      this.$refs.download[0].style.height = "0px";
    },
    //登录
    login(p) {
      console.log(p);
      if (!p) {
        let url = window.location.origin;
        if (url.match(RegExp(/xiaoguobx/g))) {
          url = window.location.origin + "/student/";
        } else {
          url = "https://www.xiaoguoai.cn" + "/s/";
          // window.open(`https://www.xiaoguoai.cn/s/`);
        }
        window.location.href = url;
      } else {
        let url = window.location.origin;
        if (url.match(RegExp(/xiaoguobx/g))) {
          url = window.location.origin + "/teacher/";
        } else {
          url = "https://www.xiaoguoai.cn" + "/t/";
          // window.open(`https://www.xiaoguoai.cn/t/`);
        }
        window.location.href = url;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '../style/Header.styl';
/deep/.el-autocomplete{
  display:flex;
  align-items:center
}
.header-shadow {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
}
.icon-with-badge{
  position: relative;
  display: inline-block;
}
.badge {
  position: absolute;
  min-width:14px;
  min-height:14px;
  padding:2px;
  line-height:14px;
  text-align:center;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  transform: translate(50%, -50%);
}
.da{
  display:flex;
  align-items:center;
}
.a_targt {
  color #949494
  text-decoration none
}
.header-1-2 div{
  width 100px
  text-align:center
}
.header-1-2>div:nth-child(1){
      position: relative;
    right: -25px;
}
.carousel{
  // height: 700px;
  min-width: 1200px
  width 100%
  height 40vw
  min-height 520px
  .img{
    height: 100%;
    width 100%;
  }
}
</style>
<style>
.el-carousel__container {
  height: 100% !important;
}

.download {
  position: relative;
}
.downloadTag {
  position: absolute;
  background-color: white;
  color: black;
  border-radius: 5px;
  overflow: hidden;
}
.downloadTag > div {
  height: 0px;
  line-height: 50px;
  transition: height 0.5s;
}

.download:hover .downloadTag > div {
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.downloadTag > div:hover {
  color: rgba(22, 70, 244, 0.738);
}
</style>
